import LinkTo from 'components/linkTo';
import { company, resources, socialMediaLinks } from 'content/navigation/links';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import styles from './FooterNavigation.module.scss';

const ITEMS_PER_ROW = 8;

const Column = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <div className="d-flex fd-column">
    <div className={`mb8 p-h4 tc-grey-700 ${styles.header}`}>{title}</div>
    <div className={styles.divider} />
    {children}
  </div>
);

const Product = ({ url, name }: { url: string; name: string }) => (
  <LinkTo key={url} href={url} className={`mt16 p-p ${styles.link}`}>
    {name}
  </LinkTo>
);

type ProductsColumn = { id: number; products: ProductLink[] }[];

const FooterNavigation = ({
  productsMenu,
}: {
  productsMenu: FooterProductsMenu;
}) => {
  const { t } = useTranslation();
  const columns = Math.ceil(productsMenu.products.length / ITEMS_PER_ROW);

  let productsByCol: ProductsColumn = [];
  for (let i = 0; i < columns; i++) {
    const slicedProducts = productsMenu.products.slice(
      i * ITEMS_PER_ROW,
      (i + 1) * ITEMS_PER_ROW
    );
    productsByCol = [...productsByCol, { id: i, products: slicedProducts }];
  }

  const router = useRouter();
  const localizedResourceLinks = resources(t).filter(
    ({ locales }) =>
      !(router.locale && locales && !locales.includes(router.locale))
  );
  const localizedCompanyLinks = company(t).filter(
    ({ locales }) =>
      !(router.locale && locales && !locales.includes(router.locale))
  );

  return (
    <div
      className={`pb24 d-grid ${styles.container}`}
      style={{ gridTemplateColumns: `repeat(${2 + columns}, 1fr)` }}
    >
      {productsByCol.map(({ id: columnId, products }) => (
        <div key={columnId} className="d-flex fd-column">
          {columnId === 0 && (
            <div className={`mb8 p-h4 tc-grey-700 ${styles.header}`}>
              {productsMenu.title}
            </div>
          )}
          <div
            className={`${styles.divider} ${
              columnId !== 0 ? `${styles.hiddenOnMobile} mt32` : ''
            }`}
          />
          {products.map(({ id: productId, name, url }) => (
            <Product key={productId} name={name} url={url} />
          ))}
        </div>
      ))}

      {localizedResourceLinks.length > 0 && (
        <Column title={t('footer.category.resource.title')}>
          {localizedResourceLinks.map(({ id, name, url }) => (
            <Product key={id} name={name} url={url} />
          ))}
        </Column>
      )}

      <Column title={t('footer.category.company.title')}>
        {localizedCompanyLinks.map(({ id, name, url }) => (
          <Product key={id} name={name} url={url} />
        ))}
        <div
          className={`mt24 d-flex gap24 ${styles.socialMediaLinksContainer}`}
        >
          {socialMediaLinks.map(({ id, name, url, image }) => (
            <a key={id} href={url}>
              <Image src={image} alt={name} width={24} height={24} />
            </a>
          ))}
        </div>
      </Column>
    </div>
  );
};

export default FooterNavigation;
