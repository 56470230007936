// NEXT_PUBLIC_ENVIRONMENT can be set to either staging, production or development
export const isProduction = (() => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  if (!environment) {
    throw new Error(
      'NEXT_PUBLIC_ENVIRONMENT is undefined. This will lead to unexpected behaviour of the website.'
    );
  }

  return environment === 'production';
})();
