import { LOCALES } from 'constants/i18n';

export const getRedirectPathWithLocale = (
  localeToRedirect: string,
  path: string
) => {
  const validLocale = LOCALES.find((locale) => locale === localeToRedirect);
  if (!validLocale) return;
  return path === '/' ? `/${localeToRedirect}` : `/${localeToRedirect}${path}`;
};

export const getLocaleIdentifierFromLocaleCookie = (localeCookie: string) => {
  return `${localeCookie}-de`;
};

export const getLocaleCookieFromLocaleIdentifier = (locale: Locale) =>
  locale.split('-')[0];

export const getRegionCookieFromLocaleIdentifier = (locale: Locale) =>
  locale.split('-')[1];
