import { website } from '@getpopsure/private-constants';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { nonProductLinks } from 'constants/internalLinks';
import { NextRouter } from 'next/router';

/**
 * This function will return the canonical url for the route.
 * It'll remove the /en-de/ and add the /de-de/, /en-es/ or /es-es/ if needed.
 * @param router the NextJs router returned by useRoute()
 */
export const canonicalUrlForRouter = (
  router: Pick<NextRouter, 'locale' | 'asPath'>
): string => {
  const pathWithoutQuery = router.asPath.split('?')[0];

  const localeBase =
    router.locale === ENGLISH_LOCALE_IDENTIFIER ? '' : `/${router.locale}`;
  const isHomepage = (path: string) => path === '/';
  const getPathWithLocale = (path: string) =>
    isHomepage(path) ? localeBase : localeBase + path;

  const isRouteWithEnDeCanonical = [
    nonProductLinks.ABOUT_US,
    nonProductLinks.BECOME_A_PARTNER,
    nonProductLinks.CAREER,
  ].includes(pathWithoutQuery);

  const path = isRouteWithEnDeCanonical
    ? pathWithoutQuery
    : getPathWithLocale(pathWithoutQuery);
  return website.base + path;
};
