import '@popsure/dirty-swan/dist/index.css';
import 'css/app.scss';
import 'css/index.scss';
import 'css/animation.scss';
import '@getpopsure/cookie-consent/dist/style.css';

import { CookieBanner } from '@getpopsure/cookie-consent';
import { setCookieLocale } from '@getpopsure/i18n-react';
import { socialMedia, website } from '@getpopsure/private-constants';
import {
  initialize as initializeUtmTracker,
  populateTrackingObjectFromUrl,
} from '@getpopsure/tracker';
import { PrismicPreview } from '@prismicio/next';
import Footer from 'components/footer';
import { setupIntercomConfiguration } from 'components/IntercomLoaderWrapper/utils/setupIntercomConfiguration';
import NavigationBar from 'components/navigationBar';
import PageNotTranslatedWrapper from 'components/PageNotTranslatedWrapper';
import { FEATHER_DOMAIN } from 'constants/cookies';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { nonCmsPathsDe, nonCmsPathsEn } from 'constants/internalLinks';
import { PRISMIC_PRODUCTION_TAG } from 'constants/prismic';
import { useNavOffset } from 'hooks/useNavOffset';
import Cookies from 'js-cookie';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config.js';
import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import { createPrismicClient, repositoryName } from 'prismicio';
import React, { useEffect } from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { handleFinanceAdsCookie } from 'services/financeAds';
import { useTracker } from 'services/tracker';
import { NavigationDocument } from 'types.generated';
import { canonicalUrlForRouter } from 'util/canonicalUrlForRoute';
import {
  getLocaleCookieFromLocaleIdentifier,
  getRegionCookieFromLocaleIdentifier,
} from 'util/getRedirectPathFromLocaleCookie';
import { isProduction } from 'util/isProduction';
import { parseNestedPath } from 'util/prismicPathResolver';

import { ModalWrapper } from '../context';
import { setTrackersAfterConsent } from '../services/tracker/setTrackersAfterConsent';

declare const AOS: {
  init: () => void;
  refresh: () => void;
  refreshHard: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomAppProps<P = any> = {
  pageProps: P;
  mainNav: NavigationDocument;
  footer: NavigationDocument;
  cmsUidsEn: Array<string>;
  cmsUidsDe: Array<string>;
  isHiring?: boolean;
} & Omit<AppProps<P>, 'pageProps'>;

/**
 * Initialize the cookie tracker
 * to allow debugging of utm params on dev environment
 */
const cookieTrackerOptions =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
    ? { cookieAttributes: { expires: 30, domain: 'localhost' } }
    : {};

initializeUtmTracker(cookieTrackerOptions);

const MyApp = ({
  Component,
  pageProps,
  mainNav,
  footer,
  cmsUidsEn,
  cmsUidsDe,
  isHiring,
}: CustomAppProps) => {
  const router = useRouter();
  const canonicalUrl = canonicalUrlForRouter(router);

  useEffect(() => {
    const setLocaleCookie = () => {
      const currentLocale = router.locale || 'en-de';

      setCookieLocale(
        getLocaleCookieFromLocaleIdentifier(currentLocale),
        FEATHER_DOMAIN
      );

      // set the region cookie.
      // for now this is hardcoded for testing purposes.
      // we will move the logic to the appropriate library and import that functionality.
      const region = getRegionCookieFromLocaleIdentifier(currentLocale);
      Cookies.set('_firi', region, { domain: FEATHER_DOMAIN });
    };

    setLocaleCookie();
  }, [router.locale]);

  setupIntercomConfiguration();

  useTracker();

  useEffect(() => {
    handleFinanceAdsCookie();
    populateTrackingObjectFromUrl();
  }, []);

  useEffect(() => {
    const refreshAOS = () => typeof AOS !== 'undefined' && AOS?.refreshHard?.();

    router.events.on('routeChangeComplete', refreshAOS);

    return () => {
      router.events.off('routeChangeComplete', refreshAOS);
    };
  }, [router.events]);

  const { navOffset, allRefs } = useNavOffset();

  const cmsPathsEn = cmsUidsEn.map(
    (uid) => `/${parseNestedPath(uid as string)}`
  );

  const cmsPathsDe = cmsUidsDe.map(
    (uid) => `/${parseNestedPath(uid as string)}`
  );

  const showLogInButton = Boolean(
    router.locale &&
      [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER].includes(
        router.locale
      )
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.css"
        />
      </Head>
      <Script
        src="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.js"
        strategy="lazyOnload"
        onReady={() => {
          AOS.init();
        }}
      />
      <DefaultSeo
        canonical={canonicalUrl}
        titleTemplate="%s | Feather"
        dangerouslySetAllPagesToNoFollow={!isProduction}
        dangerouslySetAllPagesToNoIndex={!isProduction}
        openGraph={{
          title: 'Feather',
          description:
            'For all your German insurance needs online and in English',
          url: canonicalUrl,
          type: 'website',
          images: [
            {
              url: `${website.base}/open-graph-1.jpg`,
              width: 1200,
              height: 630,
              alt: 'Composition with “Honest, simple insurance” written on the right, two persons looking at a laptop on a kitchen counter on the left.',
            },
            {
              url: `${website.base}/open-graph-2.jpg`,
              width: 1200,
              height: 630,
              alt: 'Composition with "Honest, simple insurance" written on the right hand side, two women sitting on the stairs looking at the Feather app on their phone on the left hand side.',
            },
          ],
        }}
      />
      <OrganizationJsonLd
        type="Organization"
        name="Feather"
        slogan="Honest, simple insurance."
        legalName="Popsure Deutschland GmbH"
        logo={`${website.base}/logo-112x112.png`}
        sameAs={[
          socialMedia.facebook,
          socialMedia.instagram,
          socialMedia.linkedIn,
        ]}
        url={website.base}
      />
      <LiveChatLoaderProvider
        provider="intercom"
        providerKey={process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID ?? ''}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <NavigationBar
            allRefs={allRefs}
            key={router.asPath}
            data={mainNav.data.slices}
            showLogInButton={showLogInButton}
            isHiring={isHiring}
          />
          <ModalWrapper>
            <PageNotTranslatedWrapper
              allPaths={[...cmsPathsEn, ...nonCmsPathsEn]}
              germanPaths={[...cmsPathsDe, ...nonCmsPathsDe]}
            >
              <Component
                navOffset={navOffset}
                germanPaths={[...cmsPathsDe, ...nonCmsPathsDe]} // for homepage
                {...pageProps}
              />
            </PageNotTranslatedWrapper>
          </ModalWrapper>
          <Footer
            condensed={pageProps?.condensedFooter}
            data={footer.data.slices}
          />
          <CookieBanner
            onSave={setTrackersAfterConsent}
            allowScrollOnPaths={['/privacy-policy', '/impressum']}
          />
        </PrismicPreview>
      </LiveChatLoaderProvider>
    </>
  );
};

MyApp.getInitialProps = async ({ router }: AppContext) => {
  const client = createPrismicClient();
  const mainNav = await client.getByUID('navigation', 'main-navigation', {
    lang: router.locale,
  });
  const footer = await client.getByUID('navigation', 'footer', {
    lang: router.locale,
  });

  const enPages = await client.getAllByType('page', {
    lang: ENGLISH_LOCALE_IDENTIFIER,
  });
  const cmsUidsEn = enPages
    .filter((page) => page.tags.includes(PRISMIC_PRODUCTION_TAG))
    .map((page) => page.uid);
  const dePages = await client.getAllByType('page', {
    lang: GERMAN_LOCALE_IDENTIFIER,
  });
  const cmsUidsDe = dePages
    .filter((page) => page.tags.includes(PRISMIC_PRODUCTION_TAG))
    .map((page) => page.uid);

  const hiringJob = (await client.getAllByType('job'))
    .map((job) => job.data)
    .find((job) => !!job.hiring);

  const isHiring = !!hiringJob;

  return { mainNav, footer, cmsUidsEn, cmsUidsDe, isHiring };
};

export default appWithTranslation(MyApp, nextI18NextConfig);
