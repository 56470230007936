import LinkTo from 'components/linkTo';
import HiringBadge from 'components/navigationBar/components/HiringBadge';
import { nonProductLinks } from 'constants/internalLinks';
import React from 'react';

import styles from './style.module.scss';

export const DropdownMenu = ({
  menu,
  isHiring,
}: {
  menu: ProductLink[];
  isHiring?: boolean;
}) => {
  return (
    <div className={`pb32 px40 br8 ${styles.dropdown} ${styles.menuContainer}`}>
      {menu.map(({ name, url }) => (
        <div key={name} className="d-flex ai-center gap8 mt24">
          <div>
            <LinkTo
              href={url}
              className={styles.productContainer}
              aria-label={name}
            >
              <div className={styles.productTitle}>{name}</div>
            </LinkTo>
          </div>
          {url === nonProductLinks.CAREER && isHiring && <HiringBadge />}
        </div>
      ))}
    </div>
  );
};
