import { saveFinanceAdsTrackingIdToCookie } from './api';

export const handleFinanceAdsCookie = async () => {
  if (typeof window !== 'undefined') {
    const originalURLParams = new URLSearchParams(window.location.search);

    const financeAdsTrackingId = originalURLParams.get('s_id');

    if (financeAdsTrackingId) {
      await saveFinanceAdsTrackingIdToCookie(financeAdsTrackingId);
    }
  }
};
