import { getCurrentConsent } from '@getpopsure/cookie-consent';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { initializeTrackers } from './initializeTrackers';

export const useTracker = () => {
  const router = useRouter();

  useEffect(() => {
    const handler = async (shouldInitialize: boolean) => {
      if (
        !process.browser ||
        !process.env.collectorUrl ||
        !process.env.collectorAppId
      ) {
        return;
      }
      const { trackPageView } = await import('@getpopsure/analytics');

      if (shouldInitialize) {
        const consent = getCurrentConsent();
        await initializeTrackers(consent);
      }

      trackPageView();
    };

    handler(true);

    router.events.on('routeChangeComplete', () => handler(false));

    return () => {
      router.events.off('routeChangeComplete', () => handler(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
