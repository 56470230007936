import { Radio } from '@popsure/dirty-swan';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import flagGermany from 'public/static/components/NavigationBar/flag-de.svg';
import flagSpain from 'public/static/components/NavigationBar/flag-es.svg';
import flagFrance from 'public/static/components/NavigationBar/flag-fr.svg';
import { useRef, useState } from 'react';
import {
  getCountryFromLocaleIdentifier,
  getLanguageFromLocaleIdentifier,
} from 'util/getCountryFromLocaleIdentifier';
import { getQuerySegmentFromPath } from 'util/pathSegmentUtil';

import styles from './LanguageSwitcher.module.scss';

const countries = (t: TFunction) => [
  {
    id: 'de',
    label: t('component.languageswitcher.germany'),
    icon: flagGermany,
    iconAlt: 'flag of Germany',
    languages: [
      {
        id: 'en',
        label: 'English',
      },
      {
        id: 'de',
        label: 'Deutsch',
      },
    ],
  },
  {
    id: 'es',
    label: t('component.languageswitcher.spain'),
    icon: flagSpain,
    iconAlt: 'flag of Spain',
    languages: [
      {
        id: 'en',
        label: 'English',
      },
      {
        id: 'es',
        label: 'Español',
      },
    ],
  },
  {
    id: 'fr',
    label: t('component.languageswitcher.france'),
    icon: flagFrance,
    iconAlt: 'flag of France',
    languages: [
      { id: 'en', label: 'English' },
      { id: 'fr', label: 'Français' },
    ],
  },
];

export const LanguageSwitcher = ({
  condensed = true,
}: {
  condensed?: boolean;
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { asPath, locale: currentLocale } = useRouter();
  const { t } = useTranslation();

  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(buttonContainerRef, () => setMenuIsOpen(false));

  const selectedCountry =
    countries(t).find(
      (country) => country.id === getCountryFromLocaleIdentifier(currentLocale)
    ) || countries(t)[0];

  const selectedLanguage =
    selectedCountry.languages.find(
      (lang) => lang.id === getLanguageFromLocaleIdentifier(currentLocale)
    ) || selectedCountry.languages[0];

  const handleLanguageSwitch = (newLocale: string, redirectTo: string) => {
    window.location.href = `/${newLocale}${redirectTo}`;
    setMenuIsOpen(!menuIsOpen);
  };

  let countryOptions = {};
  countries(t).forEach((country) => {
    countryOptions = {
      ...countryOptions,
      [country.id]: {
        icon: () => (
          <Image src={country.icon} alt={country.iconAlt} height={16} />
        ),
        title: country.label,
      },
    };
  });

  return (
    <div ref={buttonContainerRef}>
      <button
        className={`p-btn--secondary d-flex jc-between ai-center c-pointer br8 ${
          styles.dropdownButton
        } ${condensed ? styles.condensedButton : styles.normalButton}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        type="button"
      >
        <Image
          src={selectedCountry.icon}
          alt={selectedCountry.iconAlt}
          height={16}
          className="mr8"
        />
        {condensed ? selectedLanguage.id.toUpperCase() : selectedLanguage.label}
      </button>
      {menuIsOpen && (
        <div
          className={`p32 br8 ${styles.dropdownContainer} ${
            condensed ? '' : styles.dropdownContainerTopOverlay
          }`}
        >
          <p className="p-h3 mb16">
            {t('component.languageswitcher.language')}
          </p>
          <div className={`${styles.languageOption} mb24`}>
            {selectedCountry?.languages.map((lang) => {
              const locale = `${lang.id}-${selectedCountry.id}`;
              return (
                <div key={locale} className="mb8">
                  <input
                    name="language"
                    value={locale}
                    className="p-radio"
                    type="radio"
                    id={locale}
                    onChange={() => handleLanguageSwitch(locale, asPath)}
                    checked={selectedLanguage.id === lang.id}
                  />
                  <label htmlFor={locale} className="p-label p-label--bordered">
                    {lang.label}
                  </label>
                </div>
              );
            })}
          </div>
          <p className="p-h3">{t('component.languageswitcher.country')}</p>
          <p className={`p-p--small tc-grey-600 mb16 ${styles.description}`}>
            {t('component.languageswitcher.description')}
          </p>
          <Radio
            wide
            inlineLayout
            classNames={{
              label: styles.countryLabel,
              option: styles.countryOption,
            }}
            value={selectedCountry.id}
            onChange={(value) => {
              const newCountry =
                countries(t).find((country) => country.id === value) ||
                countries(t)[0];
              handleLanguageSwitch(
                `${newCountry.languages[0].id}-${newCountry.id}`,
                `/${getQuerySegmentFromPath(asPath)}`
              );
            }}
            options={countryOptions}
            inlineIcon={true}
          />
        </div>
      )}
    </div>
  );
};
