import { createClient, getRepositoryName } from '@prismicio/client';
import { enableAutoPreviews } from '@prismicio/next';
import { NextApiRequest, PreviewData } from 'next';
import { env } from 'process';

import sm from './sm.json';

export const repositoryName = getRepositoryName(sm.apiEndpoint);

export const createPrismicClient = (
  config: {
    previewData?: PreviewData;
    req?: NextApiRequest;
  } = {}
) => {
  const client = createClient(sm.apiEndpoint, {
    accessToken: env.PRISMIC_ACCESS_TOKEN,
  });
  enableAutoPreviews({
    client,
    previewData: config?.previewData, // passed from [uid].page.tsx
    req: config?.req, // passed from api/preview.page.ts
  });
  return client;
};
