import type { ExtendedVerticalType, VerticalType } from 'models/product';

// for policy link section
export const productLinks: Record<VerticalType | ExtendedVerticalType, string> =
  {
    PUBLIC_HEALTH: '/public-health-insurance',
    PRIVATE_HEALTH: '/private-health-insurance',
    EXPAT_HEALTH: '/expat-health-insurance',
    DENTAL: '/dental-insurance',
    LIABILITY: '/personal-liability-insurance',
    HOUSEHOLD: '/household-insurance',
    LEGAL: '/legal-insurance',
    LIFE: '/life-insurance',
    BIKE: '/bike-insurance',
    DISABILITY: '/disability-insurance',
    TRAVEL: '/travel-health-insurance',
    DOG_LIABILITY: '/dog-liability-insurance',
    COMPANY_HEALTH: '/company-health-insurance',
    COMPANY_PENSION: '/company-pension-insurance',
    COMPANY_TRAVEL: '/company-travel-insurance',
    COMPANY_DISABILITY: '/company-disability-insurance',
    COMPANY_LIFE: '/company-life-insurance',
    PET_HEALTH: '/pet-health-insurance',
    PENSION: '/pension-insurance',
  };

type NonProduct =
  | 'CAREER'
  | 'ABOUT_US'
  | 'BECOME_A_PARTNER'
  | 'SUPPORT'
  | 'PRESS';

export const nonProductLinks: Record<NonProduct, string> = {
  CAREER: '/careers',
  ABOUT_US: '/about-us',
  BECOME_A_PARTNER: '/become-a-partner',
  SUPPORT: '/support',
  PRESS: '/press',
};

// for german non translated wrapper
export const nonCmsPathsEn = [
  '/public-health-insurance',
  '/student-health-insurance',
  '/support',
];

// for german non translated wrapper and german product grid on homepage
export const nonCmsPathsDe = ['/support'];
