import { FilledLinkToDocumentField, PrismicDocument } from '@prismicio/types';
import {
  PRISMIC_PREVIEW_TAG,
  PRISMIC_PRODUCTION_TAG,
  PRISMIC_UID_SEPARATOR,
} from 'constants/prismic';

// this is a workaround for nested path as it is not supported by Prismic
// e.g. Prismic document with uid 'public-health-insurance--aok' gets routed to page /public-health-insurance/aok

// When the page is a preview, the first segment of the path will always be 'preview'
// e.g. /preview/life-insurance or /preview/life-insurance/details
const isPreviewPath = (uidArr: string[]) => uidArr[0] === PRISMIC_PREVIEW_TAG;

const isProductionReadyPage = (
  page: PrismicDocument | FilledLinkToDocumentField
) => page.tags.includes(PRISMIC_PRODUCTION_TAG);

const isNestedPath = (uid: string) => uid.includes(PRISMIC_UID_SEPARATOR);

// Returns uid to query Prismic document
export const getUidFromPathSegments = (uidArr: string[]) => {
  const path = isPreviewPath(uidArr) ? uidArr.slice(1) : uidArr;
  return path.join(PRISMIC_UID_SEPARATOR);
};

export const getPathSegmentsFromPageDoc = (page: PrismicDocument) => {
  if (!page.uid) return [];

  const segments = page.uid.split(PRISMIC_UID_SEPARATOR);

  if (isProductionReadyPage(page)) return segments;
  return [PRISMIC_PREVIEW_TAG, ...segments];
};

export const getPathFromPageDoc = (
  page: PrismicDocument | FilledLinkToDocumentField
) => {
  if (!page.uid) return '';

  const pathName = parseNestedPath(page.uid);

  if (isProductionReadyPage(page)) return `/${pathName}`;
  return `/preview/${pathName}`;
};

export const parseNestedPath = (uid: string) =>
  isNestedPath(uid) ? uid.replaceAll(PRISMIC_UID_SEPARATOR, '/') : uid;
