// TODO: update variable names of en-de & de-de to follow en-es & es-es
export const ENGLISH_LOCALE_IDENTIFIER = 'en-de';
export const GERMAN_LOCALE_IDENTIFIER = 'de-de';
export const ENGLISH_SPAIN_LOCALE_IDENTIFIER = 'en-es';
export const SPANISH_SPAIN_LOCALE_IDENTIFIER = 'es-es';
export const ENGLISH_FRANCE_LOCALE_IDENTIFIER = 'en-fr';
export const FRENCH_FRANCE_LOCALE_IDENTIFIER = 'fr-fr';

export const LOCALES = [
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
  SPANISH_SPAIN_LOCALE_IDENTIFIER,
  ENGLISH_SPAIN_LOCALE_IDENTIFIER,
  ENGLISH_FRANCE_LOCALE_IDENTIFIER,
  FRENCH_FRANCE_LOCALE_IDENTIFIER,
] as const;
