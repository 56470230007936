import { app } from '@getpopsure/private-constants';
import { Button, UserIcon } from '@popsure/dirty-swan';
import LinkTo from 'components/linkTo';
import {
  HiringBadge,
  LanguageSwitcher,
} from 'components/navigationBar/components';
import { nonProductLinks } from 'constants/internalLinks';
import { company, resourcesMain } from 'content/navigation/links';
import Image, { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import scale from 'public/static/home/product-section/img/legal.jpg';
import React, { forwardRef, ReactNode, Ref, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import isExternalUrl from 'util/isExternalUrl';

import styles from './style.module.scss';

const SHADOW_PERCENT_SCROLL_SHOW_THRESHOLD = 5;

export default forwardRef(
  (
    {
      menu,
      showLogInButton,
      isHiring,
    }: {
      menu: ProductCategory[];
      showLogInButton: boolean;
      isHiring?: boolean;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const router = useRouter();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [openSection, setOpenSection] = useState('');
    const [showShadow, setShowShadow] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
      const percentScrolled =
        (window.pageYOffset / window.screen.availHeight) * 100;
      setShowShadow(percentScrolled > SHADOW_PERCENT_SCROLL_SHOW_THRESHOLD);
    };

    const navigateTo = (link: string) => {
      setMenuIsOpen(false);
      isExternalUrl(link) ? (window.location.href = link) : router.push(link);
    };

    const localizedResourceLinks = resourcesMain(t).filter(
      ({ locales }) =>
        !(router.locale && locales && !locales.includes(router.locale))
    );
    const localizedCompanyLinks = company(t).filter(
      ({ locales }) =>
        !(router.locale && locales && !locales.includes(router.locale))
    );

    const Dropdown = ({
      title,
      children,
    }: {
      title: string;
      children: ReactNode;
    }) => (
      <>
        <button
          onClick={() => {
            setOpenSection(openSection === title ? '' : title);
          }}
          className={`ds-interactive-component fw-bold ${styles.cell} ${
            styles.cellProduct
          } ${openSection === title ? styles.cellProductOpen : ''}`}
          type="button"
        >
          {title}
        </button>
        <AnimateHeight
          duration={300}
          height={openSection === title ? 'auto' : 0}
        >
          <div className={`px16 ${styles.dropdownChildren}`}>{children}</div>
        </AnimateHeight>
      </>
    );

    const DropdownItem = ({ name, url }: { name: string; url: string }) => (
      <button
        className={`w100 ${styles.product}`}
        onClick={() => navigateTo(url)}
        type="button"
        key={name}
      >
        {name}
        {url === nonProductLinks.CAREER && isHiring && <HiringBadge />}
      </button>
    );

    return (
      <>
        <div
          ref={ref}
          className={`${styles.container} ${
            showShadow ? styles.containerShadow : ''
          }`}
        >
          <LinkTo href="/" aria-label={t('component.navigation.logo.aria')}>
            <div className={styles.logo} />
          </LinkTo>
          {showLogInButton && (
            <button
              onClick={() => router.push(`${app.base}/account/signin`)}
              type="button"
              className={`${styles.button} ${styles.signinButton}`}
              aria-label={t('component.navigation.login.aria', 'Log in')}
            >
              <UserIcon
                size={24}
                className={styles.signinIcon}
                color="purple-500"
              />
            </button>
          )}
          <button
            onClick={() => {
              setMenuIsOpen(!menuIsOpen);
            }}
            type="button"
            className={`${styles.button} ${styles.hamburgerButton}`}
            aria-label="Menu"
          />
        </div>
        {menuIsOpen && (
          <div className={styles.overlay}>
            <div className={styles.overlayContainer}>
              <div
                className={`d-flex ai-center px16 ${styles.languageSwitcherContainer}`}
              >
                <LanguageSwitcher />
                <button
                  onClick={() => {
                    setMenuIsOpen(!menuIsOpen);
                  }}
                  type="button"
                  className={`${styles.button} ${styles.hamburgerButton} ${styles.hamburgerButtonSelected}`}
                  aria-label="Menu"
                />
              </div>
              <div className={styles.table}>
                <Dropdown title={t('component.navigation.product')}>
                  {menu.length > 1 ? (
                    <>
                      {menu.map((category) => (
                        <React.Fragment key={category.title}>
                          <CategoryTitle
                            title={category.title}
                            icon={category.icon}
                          />
                          <div className={`pb16 ${styles.products}`}>
                            {category.products.map(({ name, url }) => (
                              <DropdownItem key={name} name={name} url={url} />
                            ))}
                          </div>
                        </React.Fragment>
                      ))}
                      <LinkTo href={app.recommendationTool} className="p-a">
                        <CategoryTitle
                          title={t(
                            'component.navigation.product.recommendation.title'
                          )}
                          icon={scale}
                        />
                      </LinkTo>
                    </>
                  ) : (
                    <>
                      {menu[0].products.map(({ name, url }) => (
                        <DropdownItem key={name} name={name} url={url} />
                      ))}
                    </>
                  )}
                </Dropdown>
                <Dropdown title={t('component.navigation.company')}>
                  {localizedCompanyLinks.map(({ name, url }) => (
                    <DropdownItem key={name} name={name} url={url} />
                  ))}
                </Dropdown>
                {localizedResourceLinks.map(({ url, name }) => (
                  <LinkTo
                    className={`fw-bold ${styles.cell}`}
                    href={url}
                    key={name}
                  >
                    {name}
                  </LinkTo>
                ))}
              </div>
              {showLogInButton && (
                <div className="py24 p16">
                  <Button
                    leftIcon={<UserIcon />}
                    className="w100"
                    variant="filledGray"
                    onClick={() => router.push(`${app.base}/account/signin`)}
                  >
                    {t('component.navigation.account.link', 'My account')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

const CategoryTitle = ({
  title,
  icon,
}: {
  title: string;
  icon: string | StaticImageData;
}) => (
  <div className="p16 br8 bg-grey-100 d-flex ai-center">
    <Image className="br8" src={icon} alt={title} width={48} height={48} />
    <div className={`tc-grey-600 fw-bold ${styles.categoryTitle}`}>{title}</div>
  </div>
);
