import { app } from '@getpopsure/private-constants';
import LinkTo from 'components/linkTo';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import recommendation from 'public/static/components/NavigationBar/recommendation.jpg';
import React from 'react';

import styles from './style.module.scss';

const Category = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: string;
  children: React.ReactNode;
}) => (
  <div className={styles.category}>
    <div className={`${styles.categoryHeader} d-flex ai-center`}>
      <Image src={icon} width={40} height={40} alt={title} />
      <div className={`tc-grey-600 fw-bold ${styles.categoryTitle}`}>
        {title}
      </div>
    </div>
    <div className={styles.divider} />
    {children}
  </div>
);

export const DropdownMenuWithCategory = ({
  menu,
}: {
  menu: ProductCategory[];
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`br8 px16 ${styles.dropdown} ${styles.menuWithCategoriesContainer}`}
    >
      <div className="d-flex jc-between gap56 w100 pt32 pb48 mx-auto">
        {menu.map((category) => (
          <Category
            key={category.title}
            title={category.title}
            icon={category.icon}
          >
            {category.products.map(({ name, url }) => (
              <div className="mt24" key={name}>
                <LinkTo
                  href={url}
                  className={styles.productContainer}
                  aria-label={name}
                >
                  <div className={styles.productTitle}>{name}</div>
                </LinkTo>
              </div>
            ))}
          </Category>
        ))}
        <div className={styles.recommendationCard}>
          <Image
            src={recommendation}
            alt="Recommendation Tool shown in a phone screen"
            width={192}
            height={116}
          />
          <h4 className="p-h4 tc-grey-900 mt16 mb24 ta-center">
            {t(
              'component.navigation.product.recommendation.description',
              'Get a recommendation based on your lifestyle'
            )}
          </h4>
          <LinkTo
            href={app.recommendationTool}
            className="p-btn p-btn--primary w100"
          >
            {t('component.navigation.product.recommendation.cta', 'Explore')}
          </LinkTo>
        </div>
      </div>
    </div>
  );
};
