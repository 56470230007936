import { app } from '@getpopsure/private-constants';
import LinkTo from 'components/linkTo';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import user from 'public/static/icons/user.svg';
import userPurple from 'public/static/icons/user-purple.svg';
import React from 'react';

import styles from './style.module.scss';

export const LogInButton = () => {
  const { t } = useTranslation();
  return (
    <LinkTo
      className={`d-flex ai-center gap8 br4 ${styles.logInButton}`}
      href={`${app.base}/account/signin`} // TODO: Safer way to access web app routes
    >
      <Image
        className={styles.loginIcon}
        src={user}
        width={20}
        height={20}
        alt=""
      />
      <Image
        className={styles.loginIconHover}
        src={userPurple}
        width={20}
        height={20}
        alt=""
      />
      {t('component.navigation.account.link', 'My account')}
    </LinkTo>
  );
};
