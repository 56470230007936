import {
  app,
  blog,
  faq,
  legal,
  socialMedia,
  website,
} from '@getpopsure/private-constants';
import {
  ENGLISH_FRANCE_LOCALE_IDENTIFIER,
  ENGLISH_LOCALE_IDENTIFIER,
  FRENCH_FRANCE_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { nonProductLinks } from 'constants/internalLinks';
import { StaticImageData } from 'next/image';
import { TFunction } from 'next-i18next';
import facebookIcon from 'public/static/footer/facebook.svg';
import instagramIcon from 'public/static/footer/instagram.svg';
import linkedinIcon from 'public/static/footer/linkedin.svg';

// links in footer
export const resources = (t: TFunction): ProductLink[] => [
  {
    id: '1',
    name: t('component.navigation.support'),
    url: nonProductLinks.SUPPORT,
    locales: [
      ENGLISH_LOCALE_IDENTIFIER,
      GERMAN_LOCALE_IDENTIFIER,
      FRENCH_FRANCE_LOCALE_IDENTIFIER,
      ENGLISH_FRANCE_LOCALE_IDENTIFIER,
    ],
  },
  {
    id: '2',
    name: t('component.navigation.blog'),
    url: blog.base,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
  {
    id: '3',
    name: t('footer.category.resource.faq'),
    url: faq.base,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
  {
    id: '4',
    name: t('footer.category.resource.recommendation'),
    url: app.recommendationTool,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
];

// links in top navigation
export const resourcesMain = (t: TFunction): ProductLink[] => [
  {
    name: t('component.navigation.blog'),
    url: blog.base,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
  {
    name: t('component.navigation.support'),
    url: nonProductLinks.SUPPORT,
    locales: [
      ENGLISH_LOCALE_IDENTIFIER,
      GERMAN_LOCALE_IDENTIFIER,
      ENGLISH_FRANCE_LOCALE_IDENTIFIER,
      FRENCH_FRANCE_LOCALE_IDENTIFIER,
    ],
  },
];

export const company = (t: TFunction): ProductLink[] => [
  {
    id: '1',
    name: t('component.navigation.company.about'),
    url: nonProductLinks.ABOUT_US,
  },
  {
    id: '2',
    name: t('component.navigation.company.careers'),
    url: nonProductLinks.CAREER,
  },
  {
    id: '3',
    name: t('component.navigation.company.press'),
    url: website.press,
  },
  {
    id: '4',
    name: t('component.navigation.company.partnership'),
    url: nonProductLinks.BECOME_A_PARTNER,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
];

export const socialMediaLinks: {
  id: string;
  name: string;
  image: StaticImageData;
  url: string;
}[] = [
  {
    id: '1',
    name: 'Facebook',
    image: instagramIcon,
    url: socialMedia.instagram,
  },
  {
    id: '2',
    name: 'Instagram',
    image: facebookIcon,
    url: socialMedia.facebook,
  },
  {
    id: '3',
    name: 'LinkedIn',
    image: linkedinIcon,
    url: socialMedia.linkedIn,
  },
];

export const legals = (t: TFunction): ProductLink[] => [
  { name: t('footer.category.legal.imprint'), url: website.impressum },
  {
    name: t('footer.category.legal.privacy'),
    url: website.privacyPolicy,
  },
  {
    name: t('footer.category.legal.terms'),
    url: website.termsAndConditions,
  },
  {
    name: t('footer.category.legal.security'),
    // TODO: add to private constants
    url: '/security/vulnerability-disclosure-policy',
  },
  {
    name: t('footer.category.legal.introductoryinfo'),
    url: legal.introductoryInformation,
  },
];
